@font-face {
  font-weight: 400;
  font-family: 'TT-Interphases';
  font-style: normal;
  font-display: swap;
  src: url('../assets/fonts/TT-Interphases/TT-Interphases-Regular.woff2')
    format('woff2'),
    url('../assets/fonts/TT-Interphases/TT-Interphases-Regular.ttf')
    format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'TT-Interphases';
  font-style: normal;
  font-display: swap;
  src: url('../assets/fonts/TT-Interphases/TT-Interphases-Medium.woff2')
    format('woff2'),
    url('../assets/fonts/TT-Interphases/TT-Interphases-Medium.ttf')
    format('truetype');
}
