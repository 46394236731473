@font-face {
  font-weight: 400;
  font-family: "TT-Interphases";
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/TT-Interphases/TT-Interphases-Regular.woff2") format("woff2"), url("../assets/fonts/TT-Interphases/TT-Interphases-Regular.ttf") format("truetype");
}
@font-face {
  font-weight: 500;
  font-family: "TT-Interphases";
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/TT-Interphases/TT-Interphases-Medium.woff2") format("woff2"), url("../assets/fonts/TT-Interphases/TT-Interphases-Medium.ttf") format("truetype");
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

main {
  display: block;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-size: 1em;
  font-family: monospace;
}

a {
  background-color: transparent;
}

abbr[title] {
  text-decoration: underline;
  border-bottom: none;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-size: 1em;
  font-family: monospace;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1.15;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  display: table;
  box-sizing: border-box;
  max-width: 100%;
  padding: 0;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

p {
  margin: 0;
  padding: 0;
}

* {
  text-transform: lowercase;
  outline: none;
  user-select: none;
  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: "ss08";
}
* ::-webkit-scrollbar {
  display: none;
}

html {
  font-weight: 500;
  font-size: 0.5208333333vw;
  font-family: "TT-Interphases", "Roboto", sans-serif;
}
@media only screen and (max-width: 1024px) {
  html {
    font-size: 0.9765625vw;
  }
}
@media only screen and (max-width: 428px) {
  html {
    font-size: 2.3364485981vw;
  }
}

body,
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #1b1b1b;
}

body::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
}
.scroll-container_desktop {
  height: 100vh;
  will-change: scroll-position;
}

.wrapper {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: #ffffff;
}

.container {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding-right: 5rem;
  padding-left: 5rem;
}
@media only screen and (max-width: 1024px) {
  .container {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media only screen and (max-width: 428px) {
  .container {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.page {
  position: relative;
  width: 100%;
}

.scroll-disable {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.pointer-disable {
  pointer-events: none;
}

.cell {
  position: relative;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
}
.cell_reverse {
  justify-content: flex-end;
}
.cell_space-2 {
  flex-grow: 2;
}
.cell_space-3 {
  flex-grow: 3;
}
.cell_space-4 {
  flex-grow: 4;
}
.cell_space-5 {
  flex-grow: 5;
}
.cell_space-6 {
  flex-grow: 6;
}
.cell_space-7 {
  flex-grow: 7;
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  color: #000000;
  font-weight: 500;
  font-family: "TT-Interphases", "Roboto", sans-serif;
  text-decoration: none;
}

h1 {
  font-size: 30rem;
  line-height: 0.65;
}
@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 20rem;
    line-height: 0.65;
  }
}
@media only screen and (max-width: 428px) {
  h1 {
    font-size: 10rem;
    line-height: 0.65;
  }
}

h2 {
  font-size: 20rem;
  line-height: 0.95;
}
@media only screen and (max-width: 1024px) {
  h2 {
    font-size: 13rem;
    line-height: 0.95;
  }
}
@media only screen and (max-width: 428px) {
  h2 {
    font-size: 8rem;
    line-height: 0.95;
  }
}
h2.spec {
  font-weight: 400;
}

h3 {
  font-size: 16rem;
  line-height: 0.95;
}

h4 {
  font-size: 10rem;
  line-height: 0.9;
}
@media only screen and (max-width: 1024px) {
  h4 {
    font-size: 8rem;
    line-height: 0.9;
  }
}
@media only screen and (max-width: 428px) {
  h4 {
    font-size: 5rem;
    line-height: 0.9;
  }
}

.title {
  color: #000000;
  font-size: 6rem;
  line-height: 1;
  text-decoration: none;
}
@media only screen and (max-width: 1024px) {
  .title {
    font-size: 5rem;
    line-height: 1;
  }
}
@media only screen and (max-width: 428px) {
  .title {
    font-size: 3.6rem;
    line-height: 1;
  }
}

.subtitle {
  color: #000000;
  text-decoration: none;
}
.subtitle_large {
  font-size: 4rem;
  line-height: 1;
}
@media only screen and (max-width: 1024px) {
  .subtitle_large {
    font-size: 3rem;
    line-height: 1;
  }
}
@media only screen and (max-width: 428px) {
  .subtitle_large {
    font-size: 2.2rem;
    line-height: 1;
  }
}
.subtitle_medium {
  font-size: 3rem;
  line-height: 1;
}
@media only screen and (max-width: 1024px) {
  .subtitle_medium {
    font-size: 2.4rem;
    line-height: 1;
  }
}
@media only screen and (max-width: 428px) {
  .subtitle_medium {
    font-size: 2rem;
    line-height: 1;
  }
}
.subtitle_medium-spec {
  font-weight: 400;
  font-size: 3rem;
  line-height: 1;
}
@media only screen and (max-width: 1024px) {
  .subtitle_medium-spec {
    font-size: 2.4rem;
    line-height: 1;
  }
}
@media only screen and (max-width: 428px) {
  .subtitle_medium-spec {
    font-size: 2rem;
    line-height: 1;
  }
}

.text {
  color: #000000;
  text-decoration: none;
}
.text_large {
  font-size: 2.2rem;
  line-height: 1.45;
}
.text_large-spec {
  font-size: 2.2rem;
  line-height: 1.3;
}
.text_medium {
  font-size: 2rem;
  line-height: 1.45;
}
.text_medium-spec {
  font-size: 2rem;
  line-height: 1.2;
}
.text_small {
  font-size: 1.8rem;
  line-height: 1.4;
}
.text_small-spec {
  font-size: 1.8rem;
  line-height: 1.2;
}
.text_default {
  font-size: 1.6rem;
  line-height: 1.15;
}
@media only screen and (max-width: 1680px) {
  .text_default {
    font-size: 16px;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 1440px) {
  .text_default {
    font-size: 14px;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 1024px) {
  .text_default {
    font-size: 1.4rem;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 428px) {
  .text_default {
    font-size: 1.6rem;
    line-height: 1.15;
  }
}
.text_default-spec {
  font-size: 1.6rem;
  line-height: 1.15;
}
@media only screen and (max-width: 1680px) {
  .text_default-spec {
    font-size: 16px;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 1440px) {
  .text_default-spec {
    font-size: 14px;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 1024px) {
  .text_default-spec {
    font-size: 1.4rem;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 428px) {
  .text_default-spec {
    font-size: 1.4rem;
    line-height: 1.15;
  }
}
.text_description {
  font-size: 1.6rem;
  line-height: 1.15;
}
@media only screen and (max-width: 1024px) {
  .text_description {
    font-size: 1.4rem;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 428px) {
  .text_description {
    font-size: 1.3rem;
    line-height: 1.15;
  }
}
.text_caps {
  font-size: 1.2rem;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (max-width: 1680px) {
  .text_caps {
    font-size: 12px;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 1440px) {
  .text_caps {
    font-size: 11px;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 1024px) {
  .text_caps {
    font-size: 1.1rem;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 428px) {
  .text_caps {
    font-size: 1.1rem;
    line-height: 1.15;
  }
}
.text_quote {
  font-size: 7rem;
  line-height: 0.95;
}
@media only screen and (max-width: 1024px) {
  .text_quote {
    font-size: 5rem;
    line-height: 0.95;
  }
}
@media only screen and (max-width: 428px) {
  .text_quote {
    font-size: 4rem;
    line-height: 1;
  }
}

.mini-tags {
  color: #000000;
  text-decoration: none;
}
.mini-tags_tag {
  font-size: 1.4rem;
  line-height: 1.15;
  text-transform: uppercase;
}
@media only screen and (max-width: 1024px) {
  .mini-tags_tag {
    font-size: 1.2rem;
    line-height: 1.15;
  }
}
@media only screen and (max-width: 428px) {
  .mini-tags_tag {
    font-size: 1rem;
    line-height: 1.15;
  }
}
.mini-tags_and {
  color: #ffffff;
  font-size: 2rem;
  line-height: 1;
}
@media only screen and (max-width: 1024px) {
  .mini-tags_and {
    font-size: 1.4rem;
    line-height: 1;
  }
}
@media only screen and (max-width: 428px) {
  .mini-tags_and {
    font-size: 1.2rem;
    line-height: 1;
  }
}

.menu-item {
  color: #000000;
  font-size: 10rem;
  line-height: 0.95;
  text-decoration: none;
}
@media only screen and (max-width: 428px) {
  .menu-item {
    font-size: 6rem;
    line-height: 0.95;
  }
}