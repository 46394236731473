// Layout
$original: 1920px;

// Desktop
$imac: 2560px;
$macbook-15-new: 1680px;
$macbook-15-old: 1440px;
$macbook-13: 1280px;

// Tablet
$ipad-pro-landscape: 1366px;
$ipad-pro: 1024px;
$ipad-landscape: 1024px;
$ipad: 768px;

// Phone
$iphone-max-landscape: 926px;
$iphone-max: 428px;
$iphone-plus-landscape: 736px;
$iphone-plus: 414px;
$iphone-landscape: 812px;
$iphone: 375px;
$iphone-se-landscape: 320px;
$iphone-se: 320px;

// Project Settings
$main-font: 'TT-Interphases', 'Roboto', sans-serif;
$font-size: 10px;
$transition-fast: 0.3s ease-out;
$transition-normal: 0.6s ease-out;
$transition-slow: 0.9s ease-out;

// Colors
$black: #000000;
$white: #ffffff;
$dark-gray: #1b1b1b;
$light-gray: #efefef;
$dark-red: #d30203;
$red: #eb4626;
$orange: #f67f1b;
$yellow: #ffe600;
$blue: #31b5ff;
$purple: #854dff;
$green: #009e60;
$pink: #ff87c8;
