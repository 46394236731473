@use 'sass:math';

* {
  text-transform: lowercase;
  outline: none;
  user-select: none;
  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss08';

  ::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-weight: 500;
  font-size: math.div(100 * $font-size, $original) * 1vw;
  font-family: $main-font;

  @include breakpoint($ipad-pro) {
    font-size: math.div(100 * $font-size, $ipad-pro) * 1vw;
  }

  @include breakpoint($iphone-max) {
    font-size: math.div(100 * $font-size, $iphone-max) * 1vw;
  }
}

body,
html {
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: $dark-gray;
}

body::-webkit-scrollbar {
  display: none;
}

//body {
//  overflow: hidden;
//}

.scroll-container {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;

  &_desktop {
    height: 100vh;
    will-change: scroll-position;
  }
}

.wrapper {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  background-color: $white;
}

.container {
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 auto;
  padding-right: rem(50px);
  padding-left: rem(50px);

  @include breakpoint($ipad-pro) {
    padding-right: rem(30px);
    padding-left: rem(30px);
  }

  @include breakpoint($iphone-max) {
    padding-right: rem(20px);
    padding-left: rem(20px);
  }
}

.page {
  position: relative;
  width: 100%;
}

.scroll-disable {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.pointer-disable {
  pointer-events: none;
}

.cell {
  position: relative;
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  box-sizing: border-box;

  &_reverse {
    justify-content: flex-end;
  }

  &_space-2 {
    flex-grow: 2;
  }

  &_space-3 {
    flex-grow: 3;
  }

  &_space-4 {
    flex-grow: 4;
  }

  &_space-5 {
    flex-grow: 5;
  }

  &_space-6 {
    flex-grow: 6;
  }

  &_space-7 {
    flex-grow: 7;
  }

  //@include breakpoint($ipad-pro) {
  //  flex-basis: unset;
  //}
  //
  //@include breakpoint($iphone-max) {
  //  flex-basis: unset;
  //}
}

.list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: 500;
  font-family: $main-font;
  text-decoration: none;
}

h1 {
  font-size: rem(300px);
  line-height: 0.65;

  @include breakpoint($ipad-pro) {
    font-size: rem(200px);
    line-height: 0.65;
  }

  @include breakpoint($iphone-max) {
    font-size: rem(100px);
    line-height: 0.65;
  }
}

h2 {
  font-size: rem(200px);
  line-height: 0.95;

  @include breakpoint($ipad-pro) {
    font-size: rem(130px);
    line-height: 0.95;
  }

  @include breakpoint($iphone-max) {
    font-size: rem(80px);
    line-height: 0.95;
  }

  &.spec {
    font-weight: 400;
  }
}

h3 {
  font-size: rem(160px);
  line-height: 0.95;

  // TODO: Добавилть стили
  //@include breakpoint($ipad-pro) {}
  //
  //@include breakpoint($iphone-max) {}
}

h4 {
  font-size: rem(100px);
  line-height: 0.9;

  @include breakpoint($ipad-pro) {
    font-size: rem(80px);
    line-height: 0.9;
  }

  @include breakpoint($iphone-max) {
    font-size: rem(50px);
    line-height: 0.9;
  }
}

.title {
  color: $black;
  font-size: rem(60px);
  line-height: 1;
  text-decoration: none;

  @include breakpoint($ipad-pro) {
    font-size: rem(50px);
    line-height: 1;
  }

  @include breakpoint($iphone-max) {
    font-size: rem(36px);
    line-height: 1;
  }
}

.subtitle {
  color: $black;
  text-decoration: none;

  &_large {
    font-size: rem(40px);
    line-height: 1;

    @include breakpoint($ipad-pro) {
      font-size: rem(30px);
      line-height: 1;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(22px);
      line-height: 1;
    }
  }

  &_medium {
    font-size: rem(30px);
    line-height: 1;

    @include breakpoint($ipad-pro) {
      font-size: rem(24px);
      line-height: 1;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(20px);
      line-height: 1;
    }
  }

  &_medium-spec {
    font-weight: 400;
    font-size: rem(30px);
    line-height: 1;

    @include breakpoint($ipad-pro) {
      font-size: rem(24px);
      line-height: 1;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(20px);
      line-height: 1;
    }
  }
}

.text {
  color: $black;
  text-decoration: none;

  &_large {
    font-size: rem(22px);
    line-height: 1.45;

    // TODO: Добавить стили
    //@include breakpoint($ipad-pro) {}
    //
    //@include breakpoint($iphone-max) {}
  }

  &_large-spec {
    font-size: rem(22px);
    line-height: 1.3;
  }

  &_medium {
    font-size: rem(20px);
    line-height: 1.45;

    // TODO: Добавить стили
    //@include breakpoint($ipad-pro) {}
    //
    //@include breakpoint($iphone-max) {}
  }

  &_medium-spec {
    font-size: rem(20px);
    line-height: 1.2;
  }

  &_small {
    font-size: rem(18px);
    line-height: 1.4;

    // TODO: Добавить стили
    //@include breakpoint($ipad-pro) {}
    //
    //@include breakpoint($iphone-max) {}
  }

  &_small-spec {
    font-size: rem(18px);
    line-height: 1.2;
  }

  &_default {
    font-size: rem(16px);
    line-height: 1.15;

    @include breakpoint($macbook-15-new) {
      font-size: 16px;
      line-height: 1.15;
    }

    @include breakpoint($macbook-15-old) {
      font-size: 14px;
      line-height: 1.15;
    }

    @include breakpoint($ipad-pro) {
      font-size: rem(14px);
      line-height: 1.15;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(16px);
      line-height: 1.15;
    }
  }

  &_default-spec {
    font-size: rem(16px);
    line-height: 1.15;

    @include breakpoint($macbook-15-new) {
      font-size: 16px;
      line-height: 1.15;
    }

    @include breakpoint($macbook-15-old) {
      font-size: 14px;
      line-height: 1.15;
    }

    @include breakpoint($ipad-pro) {
      font-size: rem(14px);
      line-height: 1.15;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(14px);
      line-height: 1.15;
    }
  }

  &_description {
    font-size: rem(16px);
    line-height: 1.15;

    @include breakpoint($ipad-pro) {
      font-size: rem(14px);
      line-height: 1.15;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(13px);
      line-height: 1.15;
    }
  }

  &_caps {
    font-size: rem(12px);
    line-height: 1.15;
    text-transform: uppercase;

    @include breakpoint($macbook-15-new) {
      font-size: 12px;
      line-height: 1.15;
    }

    @include breakpoint($macbook-15-old) {
      font-size: 11px;
      line-height: 1.15;
    }

    @include breakpoint($ipad-pro) {
      font-size: rem(11px);
      line-height: 1.15;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(11px);
      line-height: 1.15;
    }
  }

  &_quote {
    font-size: rem(70px);
    line-height: 0.95;

    @include breakpoint($ipad-pro) {
      font-size: rem(50px);
      line-height: 0.95;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(40px);
      line-height: 1;
    }
  }
}

.mini-tags {
  color: $black;
  text-decoration: none;

  &_tag {
    font-size: rem(14px);
    line-height: 1.15;
    text-transform: uppercase;

    @include breakpoint($ipad-pro) {
      font-size: rem(12px);
      line-height: 1.15;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(10px);
      line-height: 1.15;
    }
  }

  &_and {
    color: $white;
    font-size: rem(20px);
    line-height: 1;

    @include breakpoint($ipad-pro) {
      font-size: rem(14px);
      line-height: 1;
    }

    @include breakpoint($iphone-max) {
      font-size: rem(12px);
      line-height: 1;
    }
  }
}

.menu-item {
  color: $black;
  font-size: rem(100px);
  line-height: 0.95;
  text-decoration: none;

  @include breakpoint($iphone-max) {
    font-size: rem(60px);
    line-height: 0.95;
  }
}
