@mixin breakpoint($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin less-than($width) {
  @media only screen and (max-width: $width - 1px) {
    @content;
  }
}

@mixin multi-less-than(
  $prop,
  $less-than-2560,
  $less-than-1920,
  $less-than-1680,
  $less-than-1440,
  $less-than-1280,
  $less-than-1024,
  $less-than-768,
  $less-than-428,
  $less-than-375
) {
  @media only screen and (max-width: $imac - 1px) {
    #{$prop}: $less-than-2560;
  }
  @media only screen and (max-width: $original - 1px) {
    #{$prop}: $less-than-1920;
  }
  @media only screen and (max-width: $macbook-15-new - 1px) {
    #{$prop}: $less-than-1680;
  }
  @media only screen and (max-width: $macbook-15-old - 1px) {
    #{$prop}: $less-than-1440;
  }
  @media only screen and (max-width: $macbook-13 - 1px) {
    #{$prop}: $less-than-1280;
  }
  @media only screen and (max-width: $ipad-pro - 1px) {
    #{$prop}: $less-than-1024;
  }
  @media only screen and (max-width: $ipad - 1px) {
    #{$prop}: $less-than-768;
  }
  @media only screen and (max-width: $iphone-max - 1px) {
    #{$prop}: $less-than-428;
  }
  @media only screen and (max-width: $iphone - 1px) {
    #{$prop}: $less-than-375;
  }
}
